@font-face {
    font-family: 'montserratlight';
    src: url('fonts/montserrat-light-webfont.woff2') format('woff2'),
         url('fonts/montserrat-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratregular';
    src: url('fonts/montserrat-regular-webfont.woff2') format('woff2'),
         url('fonts/montserrat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratmedium';
    src: url('fonts/montserrat-medium-webfont.woff2') format('woff2'),
         url('fonts/montserrat-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratsemibold';
    src: url('fonts/montserrat-semibold-webfont.woff2') format('woff2'),
         url('fonts/montserrat-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratitalic';
    src: url('fonts/montserrat-italic-webfont.woff2') format('woff2'),
         url('fonts/montserrat-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/*
@font-face {
    font-family: 'montserratbold';
    src: url('fonts/montserrat-bold-webfont.woff2') format('woff2'),
         url('fonts/montserrat-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}*/

/*@font-face {
    font-family: 'montserratextralight';
    src: url('fonts/montserrat-extralight-webfont.woff2') format('woff2'),
         url('fonts/montserrat-extralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}*/

/*
@font-face {
    font-family: 'montserratblack';
    src: url('fonts/montserrat-black-webfont.woff2') format('woff2'),
         url('fonts/montserrat-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratblack_italic';
    src: url('fonts/montserrat-blackitalic-webfont.woff2') format('woff2'),
         url('fonts/montserrat-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'montserratbold_italic';
    src: url('fonts/montserrat-bolditalic-webfont.woff2') format('woff2'),
         url('fonts/montserrat-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratextrabold';
    src: url('fonts/montserrat-extrabold-webfont.woff2') format('woff2'),
         url('fonts/montserrat-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratextrabold_italic';
    src: url('fonts/montserrat-extrabolditalic-webfont.woff2') format('woff2'),
         url('fonts/montserrat-extrabolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'montserratextralight_italic';
    src: url('fonts/montserrat-extralightitalic-webfont.woff2') format('woff2'),
         url('fonts/montserrat-extralightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}





@font-face {
    font-family: 'montserratlight_italic';
    src: url('fonts/montserrat-lightitalic-webfont.woff2') format('woff2'),
         url('fonts/montserrat-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'montserratmedium_italic';
    src: url('fonts/montserrat-mediumitalic-webfont.woff2') format('woff2'),
         url('fonts/montserrat-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratregular';
    src: url('fonts/montserrat-regular-webfont.woff2') format('woff2'),
         url('fonts/montserrat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'montserratsemibold_italic';
    src: url('fonts/montserrat-semibolditalic-webfont.woff2') format('woff2'),
         url('fonts/montserrat-semibolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratthin';
    src: url('fonts/montserrat-thin-webfont.woff2') format('woff2'),
         url('fonts/montserrat-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratthin_italic';
    src: url('fonts/montserrat-thinitalic-webfont.woff2') format('woff2'),
         url('fonts/montserrat-thinitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}*/