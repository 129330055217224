.electron-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.electron-side-container {
    flex: 1;
    overflow: auto;
}

.electron-side {
    display: flex;
    flex-direction: column;
}

.electron-side .aspect-preserve, .electron-selfvideo .aspect-preserve {
    position: relative;
    width: calc(100% - 8px);
    padding-top: 66.67%;
    margin: 0px 4px;
}
.electron-side .aspect-preserve .video-container, .electron-selfvideo .aspect-preserve .video-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.electron-selfvideo {
    border: 1px solid white;
    border-radius: 5px;
    width: 100%;
    padding: 5px 0;
}

.electron-selfvideo .electron-status-buttons {
    width: 100%;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.electron-selfvideo .electron-status-buttons > div {
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    margin: 0px 7px;
}
.electron-selfvideo .electron-status-buttons > div.selected {
    width: 20px;
    height: 20px;
    border-radius: 10px;
}

.electron-selfvideo .electron-status {
    height: 15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.electron-selfvideo .electron-stream-controls {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 35px;
}
.electron-selfvideo .electron-stream-controls > div {
    width: 20px;
}

/*
 * TODO: this is ripped from CommonsAlt.css
 * probably should combine the two .status-container and .text-dot styles into
 * Video.css
 */
.electron-side .status-container {
  height: 15px;
  width: 15px;
  font-size: 10px;
  line-height: 14px;
  border-radius: 50%;
  color: white;
  text-align: center;
}

.electron-side .text-dot{
  bottom: 30px;
  left: 8px;
  height: 15px;
  width: 15px;
  padding: 4px;
  font-size: 8px;
}

