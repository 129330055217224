.top-bar {
    height: 80px;
    width: 100%;
    position: absolute;
    background-color: white;
    z-index: 98;
    opacity: 0.8;
    display: flex;
    flex-direction: row;
    font-size: 23px;
}

.night .top-bar {
    background-color: #111;
}

.top-bar-selection {
    height: 100%;
    padding: 0 25px;
    display: flex;
    align-items: center;
}

.bold {
    font-weight: bold;
}

.can-drop {
    background-color: #4e4;
}
