#private {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#private .empty-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}

#private .private-row {
  flex: 1 1 0px;
  display: flex;
  overflow: hidden
}

#private .private-row div {
  flex: 1;
}

#private .private-row video {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

#private .exit-button{
  position: absolute;
  left: 50%;
  bottom: 20px;
  width: 40px;
  height: 40px;
  transform: translateX(-50%);
  z-index: 2;
  background-color: red;
  color: white;
  border-radius: 20px;
  font-size: 20px;
}

.exit-button i {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
