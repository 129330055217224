.settingsSection:first-child {
  margin-top: 70px;
}

.settingsSection {
  margin-top: 30px;
}

.profile-picture {
  height: 100px;
  width: 100px;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 50%;
}

.profile-picture img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

#centerHolder img{
  margin: 0 auto;
}

#centerHolder{
	display: table;
	margin: 6% auto 0px auto;
}

#textHolder{
	margin: 2% auto 2% auto;
	max-width: 400px;
	text-align: center;
}

#textHolder p{
  font-family: Calibri;
	font-size: 18px;
	margin: 0px;
	text-align: center;
}

#textHolder li {
  font-family: Calibri;
	font-size: 14px;
	margin: 0;
}

#bulletList{
	margin: 10px auto 10px auto;
	max-width: 200px;
	text-align: left;
 	font-weight: bold;
}

#bulletList li{
	font-size: 16px;
}

#buttonHolder{
	margin: 10px auto 10px auto;
	max-width: 500px;
}

#buttonHolder button{
	border-style: solid;
	border-width: 1px;
	border-color: black;
	margin: 5px;
	width: 70px;
	height: 42px;
}

#footer a{
	color: black;
	text-decoration: none;
}

#login{
	display: table;
	margin: 20px 50% 0px auto;
}

#login p{
	margin: 6px;
}

input {
	margin: 5px;
}

#footer{
	display: table;
	margin: 2% auto 50px auto;
}

#footer p{
	margin: 0px;
	text-align: center;
}

.bottomMessage {
  width: 100%;
  position: fixed;
  top: 0;
  color: white;
  padding: 10px;
}

#errorMessage {
  background-color: red;
}

#successMessage {
  background-color: limegreen;
}

h3{
	font-size: 20px;
	font-weight: normal;
	font-family: montserratregular;
}