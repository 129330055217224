.main-container {
    background-color: #fff;
    color: #111;
    height: 100%;
}

.night .main-container {
    background-color: #111;
    color: #eee;
}

.main-flex-container {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.content-container {
    position: relative;
    width: 100%;
}

.main-component {
  height: 100%;
}

.shade {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
}

.shade div {
    padding: 10px;
    background-color: white;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
}

.night .shade div {
    color: black;
}