.video-container {
    position: relative;
    border-radius: 20px;
    margin: 3px 3px 3px 3px;
}

.night .video-container {

}

.video-container video {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    object-fit: contain;
}

.video-container.animate-fill:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(100, 100, 100, 0.5);
  z-index: 99;
  animation-name: videofill;
  animation-duration: 1s;
  width: 100%;
  height: 100%;
}

@keyframes videofill {
  0% {
    height: 0px;
  }
  20% {
    height: 0px;
  }
  100% {
    height: 100%;
  }
}

.video-container-movable {
    opacity: 0.5;
}

.hidden {
    visibility: hidden;
}

.small-video-container {
    padding: 5px 10px 5px 10px;
}

.in-menu-video-container {
    padding: 0 !important;
}

.big-video-container {
    padding: 30px 20px 20px 20px;
}

.status-container {
  position: relative;
}
.status-container div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.small-main-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.small-metadata-container {
    height: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.small-picture-and-icon-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.small-name-and-picture-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.small-name-small {
    font-size: 15px;
}

.small-name-big {
    font-size: 18px;
}

.name-container {
    min-width: 90%;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    text-align: center;
    font-size: 20px;
}

.name-and-picture-container {
    width: 100%;
    position: absolute;
    top: 50%;
    right: 40%;
    transform: translate(50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.small-picture-and-icon-container .small-icon {
    height: 25px;
    width: 25px;
}

.picture-container {
    height: 50px;
    width: 50px;
    margin-bottom: 2px;
    border-radius: 50%;
    overflow: hidden;
}

.small-picture {
    height: 25px;
    width: 25px;
    margin-bottom: 0px;
    margin-right: 2px;
}

.big-picture {
    height: 150px;
    width: 150px;
    margin-bottom: 0px;
}

.big-video-container .status-container-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.big-no-video-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.big-info-container {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.big-info-container .info-name {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 15px;
}

.big-info-container .info-icon {
    height: 25px;
    width: 25px;
}

.big-info-container .info-text {
    font-size: 14px;
    font-style: italic;
    margin-left: 5px;
}

.big-info-container .info-status-text {
    font-size: 18px;
    font-style: italic;
}

.picture-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.name-and-picture-name-container {
    flex: 1;
    font-size: 90%;
    text-align: center;
}

.in-call-status, .stream-status {
    position: absolute;
    padding: 10px;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 10px;
    color: black;
}

.in-call-status {
    top: 0;
    right: 0;
    padding-right: 20px;
    text-align: right;
}
.stream-status {
    top: 0;
    left: 0;
}

.text-dot {
    border-radius: 50%;
    background-color: white;
    color: black;
    text-align: center;
    font-style: italic;
}

.text-on-large {
    position: absolute;
    top: 17px;
    height: 26px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    border-radius: 10px;
    font-size: 15px;
    font-style: italic;
}

.status-text {
    left: 10px;
}

.name-text {
    right: 10px;
}
